import { coerceArray } from '@angular/cdk/coercion';
import { NgClass, NgComponentOutlet, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmationDialogData,
  ConfirmationDialogResult
} from '@library/components/dialogs/confirmation-dialog/confirmation-dialog.model';
import { ButtonComponent } from '@ui-button';
import { IconComponent } from '@ui-icon';

@Component({
  standalone: true,
  imports: [MatDialogModule, ButtonComponent, NgClass, NgTemplateOutlet, IconComponent, NgComponentOutlet],
  selector: 'cad-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent {
  private readonly dialogRef = inject(MatDialogRef);
  readonly data: ConfirmationDialogData | null = inject(MAT_DIALOG_DATA);
  readonly messages = coerceArray(this.data?.message ?? []);

  closeDialog(result: ConfirmationDialogResult): void {
    this.dialogRef.close(result);
  }
}
