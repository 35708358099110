<div class="cad-dialog">
  @if (data?.title || data?.showCloseIcon) {
    <div class="cad-dialog-title-container" mat-dialog-title>
      @if (data?.title; as title) {
        <h1 class="cad-dialog-title">{{ title }}</h1>
      }
      @if (data?.showCloseIcon) {
        <cad-icon
          class="cad-dialog-title-icon"
          color="gray"
          cursor="pointer"
          matIconName="close"
          (click)="closeDialog({ confirmed: false })"
        ></cad-icon>
      }
    </div>
  }

  <div mat-dialog-content>
    @for (message of messages; track message) {
      <p class="cad-dialog-content-text">
        {{ message }}
      </p>
    }

    <ng-template
      [ngTemplateOutlet]="data?.contentTemplate ?? null"
      [ngTemplateOutletContext]="{ $implicit: data?.contentTemplateContext }"
    ></ng-template>
    <ng-template [ngComponentOutlet]="data?.component ?? null"></ng-template>
  </div>

  @if (!data?.hideCancelButton || !data?.hideConfirmButton) {
    <div class="cad-dialog-actions" mat-dialog-actions align="end">
      @if (!data?.hideCancelButton) {
        <button
          cadButton
          text="Cancel"
          appearance="outlined"
          class="grayed"
          (click)="closeDialog({ confirmed: false })"
        ></button>
      }

      @if (!data?.hideConfirmButton) {
        <button
          cadButton
          [text]="data?.confirmButtonText ?? 'Confirm'"
          [ngClass]="data?.confirmButtonClass"
          (click)="closeDialog({ confirmed: true })"
        ></button>
      }
    </div>
  }
</div>
