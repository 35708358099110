import { inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@library/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  ConfirmationDialogData,
  ConfirmationDialogResult
} from '@library/components/dialogs/confirmation-dialog/confirmation-dialog.model';
import { confirmationDialogConfig } from '@library/components/dialogs/confirmation-dialog/confirmation-dialog.constants';

export const getOpenConfirmationDialog = () => {
  const matDialog = inject(MatDialog);

  return (data: ConfirmationDialogData, config?: MatDialogConfig) => {
    return matDialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResult>(
      ConfirmationDialogComponent,
      {
        ...confirmationDialogConfig,
        ...(config || {}),
        data
      }
    );
  };
};
